<template>
  <div>
    <div
      v-if="
        $route.path === '/login' ||
        $route.path === '/forgot-password' ||
        $route.path === '/password-reset' ||
        $route.path === '/register' ||
        $route.path === '/'
      "
    >
      <router-view />
    </div>
    <div v-else-if="authIsLogged" class="relative">
      <div>
        <div
            class="
          uk-navbar-container
          uk-navbar-transparent
          uk-box-shadow-medium
         "
        >
          <nav class=" uk-box-shadow-medium w-screen flex" uk-navbar>
            <div class="uk-flex">
              <div class="uk-navbar-left">
                <a
                    id="navigation-menu"
                    dusk="navigation-menu"
                    class="uk-navbar-toggle uk-hidden@m uk-text-primary"
                    uk-navbar-toggle-icon
                    href="#offcanvas"
                    uk-toggle
                >


                </a>
              </div>
              <div class="uk-navbar-left">
                <router-link
                    to="/"
                    class="uk-navbar-item uk-logo vertical-divider"
                >
                  <img
                      :src="api + '/storefronts/' + storefrontId + '/logo'"
                      width="150px"
                      alt
                  />
                </router-link>
                <ul class="uk-navbar-nav uk-visible@m     ">
                  <li
                      v-for="(route, index) in mainRoutes"
                      :key="index"
                      :class="{
                    'uk-active': route.path === currentPath,
                  }"
                  >
                    <router-link :to="route.path">{{ route.name }}</router-link>
                  </li>

                  <li>
                    <a href="https://wa.link/idoz3c"  alt="contacto" target="_blanck" >
                      <div class="flex items-center">
                        <div>
                          <img width="35" src="https://cdn.icon-icons.com/icons2/2592/PNG/512/whatsapp_logo_icon_154480.png" alt="">
                        </div>
                        <div class="ml-2">
                          Contáctanos
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="uk-navbar-right ">
              <ul class="uk-navbar-nav uk-flex-middle">
                <li v-if="storefrontStorefront.type === 'redeem'">
                  <router-link to="/points">
                    <div
                        style="height: 35px"
                        class="
                      uk-margin-right
                      uk-text-default
                      uk-badge
                      uk-link-reset
                      uk-flex
                      uk-flex-middle
                    "
                    >
                      <div v-if="pointsIcon">
                        <img :src="pointsIcon" alt="" width="70px" />
                      </div>
                      <div class="uk-margin-small-left text-xs md:mr-6">
                        {{ formatThousands(pointsBalance) }}
                        {{ pointsName }} disponibles
                      </div>
                    </div>
                  </router-link>
                </li>
                <li class="uk-visible@m">
                  <div class="">
                    <form
                        class=""
                        @submit="performSearch($event)"
                    >
                     <div class="flex items-center bg-white px-4 py-4 rounded-full">
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                          </svg>

                        </div>
                        <div>
                          <input
                              v-on:keydown.enter="$event.stopPropagation()"
                              class=""
                              type="search"
                              placeholder="Buscar"
                              v-model="search"
                          />
                        </div>
                     </div>
                    </form>
                  </div>
                </li>
                <li class="uk-visible@m">
                  <a href="#" test="nav-dropdown-arrow">
                    <div class="uk-flex uk-flex-middle">
                      <router-link
                          to="/profile"
                          class="uk-link-reset"
                          test="profile-navbar-link"
                      >
                        <div class="flex items-centers">
                          <div>
                            {{ authUser.first_name }} {{ authUser.last_name }}
                          </div>
                        </div>  
                      
                    </router-link>
                      <div
                          uk-icon="chevron-down"
                          class="uk-margin-small-left"
                      ></div>
                    </div>
                  </a>
                  <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <router-link
                            to="/profile"
                            class="uk-link-reset"
                            test="profile-navbar-link"

                        >
                        <div class="flex items-center">
                          <div class="mr-2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                              </svg>
                          </div>
                          <div>
                            Mi perfil
                          </div>
                        </div>
                        </router-link>
                        <hr>
                      <li v-for="(route, index) in dropdownRoutes" :key="index">
                        <router-link :to="route.path">
                          <div class="uk-flex uk-flex-middle">
                            <div :uk-icon="route.icon"></div>
                            <div class="uk-margin-small-left">
                              {{ route.name }}
                            </div>
                          </div>
                        </router-link>
                      </li>
                      <li @click="logout" test="logout-button">
                        <hr>
                        <div class="uk-flex uk-flex-middle cursor-pointer">
                          <div uk-icon="sign-out"></div>
                          <div class="uk-margin-small-left">Cerrar sesión</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </nav>

        </div>


        <div
            class="
          uk-background-primary
          text-white
          uk-padding-small uk-flex-center uk-visible@l uk-grid-small

        "
            uk-grid
        >
          <div
              v-for="category in productsCategories.filter(
            (category) => category.product_category_id === null
          )"
              :key="'category-' + category.id"
          >
            <router-link
                test="navbar-category-link"
                :to="{
              path: '/store/search',
              query: {
                category: category.id,
              },
            }"
                class="uk-link-reset"
            >

            <div class="flex">
                <div>
                  {{ category.name }}
                </div>

                <div>
                  <span
                    v-if="category.children.length !== 0"
                    uk-icon="chevron-down"
                  ></span>
                </div>
            </div>
            
            </router-link>
            <div
                uk-drop="pos: bottom-center"
                v-if="category.children.length !== 0"
                style="width: auto !important;"

            >
              <div
                  class="
                  uk-text-center uk-border-rounded
                  w-auto
                  submenu-category
                  
              "
              >
                <div
                    v-for="category in category.children"
                    :key="'category-' + category.id"
                >
                  <router-link
                      class="uk-link-reset"
                      :to="'/store/search?category=' + category.id"
                  >
                    <div class="uk-padding-small">

                      <div class="flex items-center justify-center">
                        <div class="mr-2" v-if="category.show_icon_in_menu">
                          <img :src="category.icon_s3" :alt="category.name" width="100px">
                        </div>

                        <div v-if="category.show_name_in_menu">
                          {{ category.name  }}
                        </div>
                      </div>

                      <span
                        v-if="category.children.length !== 0"
                        uk-icon="chevron-right"
                    ></span>
                    </div>
                  </router-link>
                  <div
                      uk-drop="pos: right-top"
                      v-if="category.children.length !== 0"
                  >
                    <div
                        class="
                      uk-card uk-card-primary uk-text-center uk-border-rounded
                      category-card
                    "
                    >
                      <div
                          v-for="category in category.children"
                          :key="'category-' + category.id"
                      >
                        <router-link
                            class="uk-link-reset"
                            :to="'/store/search?category=' + category.id"
                        >
                          <div class="uk-padding-small">
                            {{ category.name }}
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-container uk-position-relative">
          <div id="content-container" class="uk-margin-bottom"></div>
          <router-view />
          <div class="uk-margin-bottom"></div>
        </div>

        <div id="offcanvas" uk-offcanvas="mode: push; overlay: true">
          <div class="uk-offcanvas-bar uk-background-primary">
            <div class="uk-panel">
              <div>
                <form
                    class="uk-search uk-search-default uk-width-1-1"
                    @submit="performSearch($event)"
                >
                  <span uk-search-icon></span>
                  <input
                      v-on:keydown.enter="$event.stopPropagation()"
                      class="uk-search-input uk-border-rounded navbar-search"
                      type="search"
                      placeholder="Buscar"
                      v-model="search"
                  />
                </form>
              </div>
              <ul class="uk-nav uk-nav-default uk-margin-small-top">
                <li>
                  <ul uk-accordion>
                    <li
                        v-for="category in productsCategories.filter(
                      (category) =>
                        category.product_category_id === null &&
                        category.children.length !== 0
                    )"
                        :key="'category-' + category.id"
                    >
                      <router-link
                          :to="'/store/search?category=' + category.id"
                          class="uk-link-reset"
                          :class="{
                        'uk-accordion-title': category.children.length !== 0,
                      }"
                      >{{ category.name }}
                        <span
                            v-if="category.children.length !== 0"
                            uk-icon="chevron-down"
                        ></span
                        ></router-link>
                      <div
                          class="uk-accordion-content"
                          v-if="category.children.length !== 0"
                      >
                        <router-link
                            v-for="category in category.children"
                            :key="'category-' + category.id"
                            class="uk-link-reset"
                            :to="'/store/search?category=' + category.id"
                        >
                          <div class="uk-padding-small">
                            {{ category.name }}
                          </div>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                    v-for="category in productsCategories.filter(
                  (category) =>
                    category.product_category_id === null &&
                    category.children.length === 0
                )"
                    :key="'category-' + category.id"
                >
                  <router-link :to="'/store/search?category=' + category.id">{{
                      category.name
                    }}</router-link>
                </li>
                <li
                    v-for="(route, index) in mainRoutes"
                    :key="index + route.path"
                    :class="{ 'uk-active': route.path === currentPath }"
                >
                  <router-link :to="route.path">{{ route.name }}</router-link>
                </li>

                <li
                    v-for="(route, index) in dropdownRoutes"
                    :key="index + route.path"
                    :class="{ 'uk-active': route.path === currentPath }"
                >
                  <router-link :to="route.path">{{ route.name }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: ['head'],

  name: "Navigation",

  data() {
    return {
      search: "",
    };
  },

  computed: {
    ...mapGetters([
      "authUser",
      "authIsLogged",
      "productsCategories",
      "pointsBalance",
      "pointsName",
      "pointsIcon",
      "storefrontStorefront",
    ]),
    mainRouteList() {
      return [
        {
          name: "Tienda",
          path: "/store",
          visible_for: ["all"],
          storefront_type: ["all"],
        },
        {
          name:
            this.storefrontStorefront.type === "b2b"
              ? "Mis cotizaciones"
              : "Mis canjes",
          path: "/quotes",
          visible_for: ["client"],
          storefront_type: ["all"],
        },
        {
          name: "Revisión de cotizaciones",
          path: "/quotes/review",
          visible_for: [
            "administrator",
            "storefront-administrator",
            "salesman",
          ],
          storefront_type: ["b2b"],
        },
        // {
        //   name: "Reportes",
        //   path: "/reports",
        //   visible_for: ["administrator", "storefront-administrator"],
        //   storefront_type: ["all"],
        // },
      ];
    },

    dropdownRouteList() {
      return [
        {
          name: "Empresas",
          path: "/businesses",
          visible_for: ["administrator", "storefront-administrator"],
          storefront_type: ["b2b"],
          icon: "thumbnails",
        },
        {
          name: "Usuarios",
          path: "/users",
          visible_for: ["administrator", "storefront-administrator"],
          storefront_type: ["all"],
          icon: "users",
        },
        {
          name: "Equipos de venta",
          path: "/sale-teams",
          visible_for: ["administrator", "storefront-administrator"],
          storefront_type: ["b2b"],
          icon: "receiver",
        },
        {
          name: "Sincronización de productos",
          path: "/product-sync",
          visible_for: ["administrator"],
          storefront_type: ["all"],
          icon: "database",
        },
        {
          name: "Productos incompletos",
          path: "/products/incomplete",
          visible_for: ["administrator"],
          storefront_type: ["all"],
          icon: "warning",
        },
        {
          name: "Restricción de productos",
          path: "/products/restrictions",
          visible_for: ["administrator"],
          storefront_type: ["all"],
          icon: "minus-circle",
        },
        {
          name: "Configuración de constantes",
          path: "/constants",
          visible_for: ["administrator"],
          storefront_type: ["all"],
          icon: "hashtag",
        },
        {
          name: "Nova",
          path: "/internal/nova",
          visible_for: ["administrator", "storefront-administrator"],
          storefront_type: ["all"],
          icon: "sign-in",
        },
      ];
    },
    currentPath() {
      return window.location.pathname;
    },
    mainRoutes() {
      if (this.authUser) {
        return this.mainRouteList
          .filter(
            (route) =>
              route.visible_for.includes(this.authUser.type.slug) ||
              route.visible_for.includes("all")
          )
          .filter(
            (route) =>
              route.storefront_type.includes(this.storefrontStorefront.type) ||
              route.storefront_type.includes("all")
          );
      } else {
        return [];
      }
    },
    dropdownRoutes() {
      if (this.authUser) {
        return this.dropdownRouteList
          .filter(
            (route) =>
              route.visible_for.includes(this.authUser.type.slug) ||
              route.visible_for.includes("all")
          )
          .filter(
            (route) =>
              route.storefront_type.includes(this.storefrontStorefront.type) ||
              route.storefront_type.includes("all")
          );
      } else {
        return [];
      }
    },
  },

  mounted() {
    if (this.authIsLogged && this.storefrontStorefront.type === "redeem") {
      this.pointsFetchBalance();
    }
    this.storefrontFetchStorefront();

    document.title = this.storefrontStorefront.name;
    const faviconLink = document.querySelector('link[rel="icon"]') || document.createElement('link');
    faviconLink.href = this.storefrontStorefront.favicon;
    if (!faviconLink.rel) {
      faviconLink.rel = 'icon';
    }
    document.head.appendChild(faviconLink);
  },

  methods: {
    ...mapActions([
      "logout",
      "pointsFetchBalance",
      "storefrontFetchStorefront",
    ]),
    performSearch(event) {
      if (event) {
        event.preventDefault();
      }
      // window.location = "/store/search?query=" + this.search;
      this.$router.push("/store/search?query=" + this.search);
      this.search = "";
    },
  },
};
</script>

<style>
.category-card {
  border: solid 2px rgb(56, 56, 56);
}

nav {
  background-color: #EEEEEE;
}

.navbar-search {
  padding: 15px 30px;
}

.text-white {
  color: white !important;
}

.background-white {
  background-color: white !important;
}

</style>


<style lang="scss">
@font-face {
  font-family: Nexa;
  src: url("./assets/fonts/NexaBold.otf");
  font-weight: bold;
}
@font-face {
  font-family: Nexa;
  src: url("./assets/fonts/Nexa Light.otf");
  font-weight: normal;
}

$global-font-family: "Nexa" !default;
$global-color: black;
$global-muted-background: #eee;
$container-max-width: 1800px;
$border-rounded-border-radius: 15px;
// UIKIT
@import "uikit/src/scss/variables.scss";
@import "uikit/src/scss/mixins.scss";
@import "uikit/src/scss/uikit.scss";

.submenu-category {
  background-color:  $global-primary-background;
  border: solid 2px rgb(56, 56, 56);
}

.dashed-border {
  border: 2px dashed;
}
#pagination-buttons{
}
// v-select
$vs-selected-bg: #dce0e6;
$vs-controls-color: black;
$vs-colors: (
  lightest: rgba(60, 60, 60, 0.26),
  light: rgba(60, 60, 60, 0.5),
  dark: #666,
  darkest: rgba(0, 0, 0, 0.15),
) !default;
$vs-border-style: none !default;
$vs-dropdown-max-height: 200px !default;
@import "vue-select/src/scss/vue-select.scss";
.vs__dropdown-toggle {
  padding: 0;
  height: 40px;
  background: #f8f8f8;
}
.vs__selected-options {
  padding: 0;
}

.vs__selected {
  margin: 0;
}
.v-select {
  padding: 0;
}

// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.glide__bullet {
  background-color: lightgrey;
}
.glide__bullet:hover {
  background-color: lightgrey;
  border: none;
}
.glide__bullet:focus {
  background-color: $global-primary-background;
  border: none;
}
.glide__bullet--active {
  background-color: $global-primary-background;
}

.glide__bullets {
  bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
